import semver from 'semver'
import { APP_USER_AGENT, BASE64_ID_REGEX } from '@/consts'

export const noop: () => void = () => {}

export function isNotNullOrUndefined<T>(
  value: T | undefined | null,
): value is T {
  return value !== undefined && value !== null
}

export const isEmpty = (obj: Object) => {
  return Object.keys(obj).length === 0
}

export const base64ToId = (base64: string) => {
  return (
    BASE64_ID_REGEX.exec(Buffer.from(base64, 'base64').toString('utf8'))?.[2] ??
    null
  )
}

export const idToBase64 = (prefix: string, id: string) => {
  if (!/^\d+$/.test(id)) return
  return Buffer.from(`${prefix}:${id}`, 'utf8').toString('base64')
}

/**
 *  @description 컴포넌트가 아닌 함수 내에서 localStorage 값이 필요한 경우 사용하는 함수
 *  key가 빈 문자열이면 initialValue를 반환합니다.
 *  localStorage에 저장된 데이터를 가져온다.
 *  localStorage에 저장된 데이터가 없으면 initialValue를 반환한다.
 *  가져온 값이 존재하는 경우, JSON.parse를 사용하여 해당 값을 파싱한 후 반환한다.
 *  가져온 값이 파싱 중에 에러가 발생한 경우 initialValue를 반환한다.
 *  @example
 *  const accessToken = getLocalStorage<string>('_accessToken_', '')
 *  @param key localStorage에 저장된 데이터의 키
 */
export const getLocalStorage = <T>(key: string, initialValue: T): T => {
  try {
    if (typeof window === 'undefined') return initialValue
    if (key.trim() === '') return initialValue
    const value = localStorage.getItem(key)
    if (value === null) return initialValue

    try {
      return JSON.parse(value) as T
    } catch {
      return value as unknown as T
    }
  } catch (error) {
    console.error('Error parsing local storage data', error)
    return initialValue
  }
}

export const setLocalStorage = <T>(key: string, newValue: T) => {
  if (typeof window === 'undefined') return
  try {
    return localStorage.setItem(key, JSON.stringify(newValue))
  } catch (error) {
    console.error('Error set local storage data', error)
  }
}

export const getSessionStorage = <T>(key: string, initialValue: T): T => {
  if (key.trim() === '') return initialValue
  if (typeof window === 'undefined') return initialValue

  try {
    const value = sessionStorage.getItem(key)
    if (value === null) return initialValue

    try {
      return JSON.parse(value) as T
    } catch {
      return value as unknown as T
    }
  } catch (error) {
    console.error('Error parsing session storage data', error)
    return initialValue
  }
}

export const setSessionStorage = <T>(key: string, newValue: T) => {
  if (typeof window === 'undefined') return
  try {
    return sessionStorage.setItem(key, JSON.stringify(newValue))
  } catch (error) {
    console.error('Error set session storage data', error)
  }
}

/**
 * @description 앱으로 메시지를 보내는 함수
 * @param type 메시지 타입
 * @param data 메시지 데이터
 */
export const postMessageToApp = (type: string, data?: any) => {
  if (!(window as any).ReactNativeWebView) return
  ;(window as any).ReactNativeWebView.postMessage(
    JSON.stringify({ type, data }),
  )
}

/**
 * @description 웹뷰인지 확인하는 함수. 앱에서 웹뷰로 쿠키를 통해 정보를 전달한다.
 */
export const isWebview = () => {
  return !!getPlatformFromCookie()
}

/**
 * react-native 앱은 웹뷰에 LunitCAREApp={플랫폼} 쿠키를 저장한다.
 * 해당 쿠키를 통해 플랫폼을 가져온다.
 * @returns android | ios | null
 */
export const getPlatformFromCookie = () => {
  if (typeof window === 'undefined') return null
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${APP_USER_AGENT}=`)
  // 쿠키가 존재하면 해당 쿠키 값을 포함한 배열이 2개의 요소를 가진다.
  if (parts.length === 2) return parts.pop()?.split(';').shift() ?? null
  return null
}

/**
 * react-native 앱은 웹뷰에 version={버전} 쿠키를 저장한다. 해당 쿠키를 통해 버전을 가져온다.
 * @returns 버전 (x.x.x) | null
 */
export const getVersionFromCookie = () => {
  if (typeof window === 'undefined') return null
  // 앱이 아니면 null을 반환한다.
  if (!isWebview()) return null
  // 문자열이 version=으로 시작하는 경우
  // 또는 쿠키 문자열의 중간에 version이 있으며, 세미콜론(;)과 공백 뒤에 나오는 경우.
  const match = document.cookie.match(/(?:^|;\s*)version=([^;]*)/)
  // 디버그 앱이면 -debug를 제거한다.
  return match ? match[1].replace('-debug', '') : null
}

/**
 * 안드로이드 앱 버전이 타겟 버전과 같거나 낮은지 확인하는 함수
 * @param targetVersion 비교할 타겟 버전
 * @returns true: 타겟 버전보다 낮거나 같은 경우, false: 타겟 버전보다 높은 경우
 */
export const checkOldAndroidVersion = (targetVersion: string) => {
  if (getPlatformFromCookie() !== 'android') return false

  const appVersion = getVersionFromCookie()
  if (!appVersion) return false

  return !semver.gt(appVersion, targetVersion)
}

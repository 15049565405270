const LOCAL_HOST = 'http://localhost:3000'

const SITE_URLS = {
  mock: `${LOCAL_HOST}`,
  develop: `${LOCAL_HOST}`,
  staging: `https://www.s.lunit.care`,
  production: 'https://www.lunit.care',
}

const SITE_URL = SITE_URLS[process.env.NEXT_PUBLIC_ENV]

const NEW_PATHS = [
  {
    // 위암 메인
    source: '/guides/main/stomach',
    destination: '/stomach/guides/main',
    permanent: true,
  },
  {
    // 갑상선암 메인
    source: '/guides/main/thyroid',
    destination: '/thyroid/guides/main',
    permanent: true,
  },
  {
    // 폐암 메인
    source: '/guides/main/lung',
    destination: '/lung/guides/main',
    permanent: true,
  },
  {
    // 대장암 메인
    source: '/guides/main/colon',
    destination: '/colon/guides/main',
    permanent: true,
  },
  {
    // 유방암 메인
    source: '/guides/main',
    destination: '/breast/guides/main',
    permanent: true,
  },
  {
    // 답변 프로세스
    source: '/board/answer-process',
    destination: '/boards/answer-process',
    permanent: true,
  },
]

module.exports = {
  SITE_URLS,
  SITE_URL,
  NEW_PATHS,
}
